/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Potvrdit účast"}>
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--30 pl--40 pr--40 pt--30" menu={true}>
            
            <Menu className="--full pb--06 pl--06 pr--06 pt--06" style={{"maxWidth":""}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Subtitle className="subtitle-box subtitle-box--invert" content={"23—6—2021"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10 pr--10" innerClassName="pl--0 pr--0" href={"/"} target={""} content={"Místo a čas"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10 pr--10" innerClassName="pl--0 pr--0" href={"/svatebni-dary"} target={""} content={"Svatební dary"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--22 w--400 ls--004 pl--10" innerClassName="pl--0 pr--0" href={"/potvrdit-ucast"} target={""} content={"Potvrdit účast"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="pb--40 pt--80" name={"informace"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 title-box--invert pt--50" content={"Potvrdit účast"}>
              </Title>

              <Text className="text-box mt--10" content={"Dejte nám vědet, jestli přijdete. Díky formuláři nám ulehčíte organizaci!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--20" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style3 ff--3 fs--22 w--300 ls--002 lh--12" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Jste vegetarián?","type":"select","options":["Ano","Ne"]},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-c583e9 --center bg--bottom --parallax pb--80 pt--60" name={"paticka"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/10556e3caf9246d09439451a659cf483_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--40 pt--40">
              
              <Title className="title-box" content={"Těšíme se na Vás!"}>
              </Title>

              <Subtitle className="subtitle-box mt--10" content={"23.6.2021"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}